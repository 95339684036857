<template>
  <div id="app">
    <Loader />
    <router-view />
    <cookie-law :buttonText="'Aceptar'" :theme="'dark-lime'" :buttonClass="'boton_cookies'">
			<div slot="message">
				Este sitio utiliza cookies para un correcto funcionamiento, entendemos que al aceptarlas está aceptando la <router-link to="/cookies">política de cookies</router-link>.
			</div>
		</cookie-law>
  </div>
</template>

<script>
import Loader from './components/Loader.vue'
import CookieLaw from 'vue-cookie-law'
export default {
  // metaInfo: {
  //   meta: [{vmid: 'og:image', property: 'og:image', content: 'https://cliptter.com/static/images/avatarclipttertwitter.png' }]
  // },
  components: {CookieLaw, Loader},
  data: () => {
    return {

    }
  }
}
</script>

<style>
</style>
