<template>
    <div>
      <NuevasCondiciones></NuevasCondiciones>
      <MenuMovil></MenuMovil>
        <!--Contenido principal-->
        <div class="container page" style="min-height: 100vh;">
          <div class="row">
            <!-- Menú lateral solo PC-->
            <MenuPc></MenuPc>
            <!-- Barra central-->
            <div class="col-12 col-md-8 col-lg-6" style="min-height: 100vh;">

              <div class="fijar-barra">
                <NavTop></NavTop>

                <!--DIV filtro autor (EN LUGAR DE DESCUBRE PONDRÁ @SwordGH, por ejemplo)-->
                <div class="row" v-if="$root.filter.autor.user" style="background-color: #15202b">
                  <div class="col-12 margin-padding-0 tarjetaMovil">
                    <a href="#" @click="filtrarPorUsuario(null, null, null)" style="position: absolute; z-index: 99; top: 4px; right: 10px; font-weight: bold; color: white; font-size: 16px"><font-awesome-icon icon="times"/></a>
                    <div class="filtroAutorBanner" :style="{ 'background-image': 'url(' + $root.filter.autor.banner + ')'}"></div>
                    <div>
                      <div class="filtroAutorAvatar" :style="{ 'background-image': 'url(' + $root.filter.autor.avatar + ')'}"></div>
                      <p style="color: white; float: left; margin-right: 10px; font-weight: normal; margin-top: 5px; margin-left: 110px;"><span style="color: white; font-weight: bold; margin-right: 10px">{{$root.filter.autor.videos}}</span>resultados</p>
                    </div>
                  </div>
                </div>

                <!--DIV filtro tema (EN LUGAR DE DESCUBRE PONDRÁ Operación Triunfo, por ejemplo)-->
                <div class="row" v-if="$root.filter.tema.tema_url" style="background-color: #15202b">
                  <div class="col-12 margin-padding-0 tarjetaMovil">
                    <a href="#" @click="filtrarPorTema(null, null, null)" style="position: absolute; z-index: 99; top: 4px; right: 10px; font-weight: bold; color: white; font-size: 16px"><font-awesome-icon icon="times"/></a>
                    <div class="filtroTemaBanner" :style="{ 'background-image': 'url(' + $root.filter.tema.banner + ')'}"></div>
                    <div>
                      <div class="filtroTemaAvatar" :style="{ 'background-image': 'url(' + $root.filter.tema.avatar + ')'}"></div>
                      <p style="color: white; float: left; margin-right: 10px; font-weight: normal; margin-top: 5px; margin-left: 110px;"><span style="color: white; font-weight: bold; margin-right: 10px">{{$root.filter.tema.videos}}</span>resultados</p>
                    </div>
                  </div>
                </div>

              <div class="row" style="background-color: #15202b;">
                <div class="col-12" style="margin-top: 15px">
                  <div class="form-group">
                    <input type="text" class="form-control" id="buscar" aria-describedby="buscar" placeholder="Buscar..." v-on:keyup.enter="buscar()" v-model="$root.filter.buscar">
                    <input type="hidden" id="autor" v-model="$root.filter.autor.user">
                  </div>
                </div>

                <div class="col-6" style="padding-left: 0; padding-right: 0" @click="$root.filter.populares = false">
                  <div class="filter_populares" :class="{'selected':!$root.filter.populares}" style="background-color: #15202b;">
                    Más recientes</div>
                </div>
                <div class="col-6" style="padding-left: 0; padding-right: 0" @click="$root.filter.populares = true" >
                  <div class="filter_populares" :class="{'selected':$root.filter.populares}" style="background-color: #15202b;">
                    Destacados</div>
                </div>
              </div>
            </div>

              <div id="snackbar" style="z-index: 9999">Link copiado</div>

              <div v-infinite-scroll="getData" infinite-scroll-disabled="busy" infinite-scroll-distance="100" id="parent-grid" class="row" style="padding: 0; padding-top: 7px">
                <!--BUCLE vídeos-->
                <template v-for="(item, index) in items">
                  <div :class="$root.tipoVista.cols">
                    <div class="infoVideo" v-if="$root.tipoVista.showInfoVideo">
                      <div style="display:flex;">
                        <div class="avatarVideo2" style="background-size: 100% 100%; justify-content:center; align-items:center;" :src="item.avatar"></div>
                        <h5 class="modal-title" id="exampleModalLongTitle" style="margin-left: 10px; color: white; font-size: 18px;margin-top: 10px; margin-bottom: 10px;">User</h5>
                      </div>
                      <div
                        style="padding: 5px; text-align: center; border-radius: 50px; color: yellow; font-weight: bold; cursor: pointer; float: right" @click="setFavorito('listado', item.id_video)">
                        <img style="width: 20px" :src="item.favorito > 0 ? icon_favno : icon_favsi">
                      </div>
                    </div>
                    <div class="div-cuadrado-video" :id="'div_preview_' + item.id_video" :style="{ 'background-image': 'url(' + getImageUrl(item) + ')'}" @click="setVideo(item)">
                      <div style="position: absolute; top: 5px; right: 10px"><img :src="item.favorito ? '@/assets/images/favsi.png' : '@/assets/images/favoritos.png'" src="@/assets/images/favsi.png" style="width: 20px" v-if="item.mi_favorito > 0"></div>
                    </div>
                    <div v-if="item.user_id == $store.state.session.id_user && $store.state.session.id_user>0|| $store.state.session.admin==1" @click="showModalEditVideo(item)" style="position: absolute; bottom: 5px; right: 10px; z-index:9; cursor: pointer;"><img src='@/assets/images/edit.png' style="width: 20px"></div>
                    <div class="infoVideo" v-if="$root.tipoVista.showInfoVideo">
                      <div style="color: white; padding: 10px">
                        <div style="font-size: 18px; font-weight: bold">{{item.protagonista}}</div>
                        <div style="font-size: 16px;">{{item.descripcion}}</div>
                      </div>
                      <div class="row" style="margin-left: 0; margin-right: 0">
                        <div class="col-12" style="padding-left: 0; padding-right: 0">
                          <div class="form-group">
                            <div style="display:flex-inline; flex-direction:row">
                              <input ref="url" type="text" class="form-control form-control-sm" :value="getUrl(item)">
                              <span @click="copyToClipboard(item)" style="right: 20px;position: absolute;top: 8px; cursor:pointer;">
                                <font-awesome-icon icon="copy"/>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row" style="color: white; margin-left: 15px;  margin-right: 0;">
                        <div class="col-6" style="padding-right: 7px; padding-left: 0" @click="share(item)">
                          <div
                            style="padding: 10px; width: 100%; text-align: center; border: 1px solid #00a478; border-radius: 50px; background-color:#00a478; font-weight: bold; cursor: pointer">
                            Compartir</div>
                        </div>
                        <div class="col-6" style="padding-left: 7px; padding-right: 14px">
                          <a style="text-decoration:none" target="_blank" class="popup" :href="getFileDownload(item)">
                            <div style="padding: 10px; width: 100%; text-align: center; border: 1px solid #1da1f2; border-radius: 50px; background-color: yellow; font-weight: bold; cursor: pointer; color: #000">Descargar</div>
                          </a>
                        </div>
                      </div>


                    </div>

                  </div>
                </template>
              </div>

              <div class="page-load-status">
                <div v-if="infiniteScroll.load" class="row">
                  <div class="col-12 text-center">
                    <img src="@/assets/images/bars.svg" alt="" style="margin-top: 50px">
                  </div>
                </div>
                <p v-else class="infinite-scroll-error"></p>
              </div>
              <div v-if="items.length == 0 && !infiniteScroll.load" class="row">
                <div class="col-12" style="text-align: center; font-weight: bold; padding: 20px">
                  <img src="@/assets/images/noresultados.png" alt="" style="width: 150px" />
                </div>
                <div class="col-12" style="text-align: center; font-weight: bold">
                  No se han encontrado resultados, se recomienda utilizar una sola palabra.
                </div>
              </div>

            </div>
            <!--Barra lateral derecha, solo PC-->
            <div class="d-none d-md-block col-md-3 col-lg-4" style="border-left: 1px solid #38444d; padding-top: 10px">
            <div style="position: sticky; top: 10px">
              <div v-if="$root.currentMenu === 'pc'">
                <Tendencias/>
              </div>
            </div>
            </div>
          </div>
        </div>

        <!-- Modal vídeo-->
        <ModalVerVideo :video="video" v-if="video"></ModalVerVideo>

        <!-- Modal para añadir-->
        <ModalAddVideo :tweet_detail="$root.tweet_detail"></ModalAddVideo>

        <!-- Modal para editar-->
        <ModalEditVideo :video="video" :tweet_detail="$root.tweet_detail"></ModalEditVideo>
    </div>
</template>

<script>
import PageMixin from './PageMixin'
import axios from "axios"
import 'jquery-confirm'
import 'jquery-confirm/dist/jquery-confirm.min.css'

import icon_favsi from '@/assets/images/favsi.png'
import icon_favno from '@/assets/images/favno.png'

var MobileDetect = require('mobile-detect');

import 'vuejs-noty/dist/vuejs-noty.css'

export default {
    name: "PageDescubre",
    mixins: [PageMixin],
    props: ['autor', 'tema'],
    data: () => {
        return {
          icon_favsi,
          icon_favno,
          items: [],
          infiniteScroll : {
            load: true,
            page: 0,
            total_pages: 0
          },
          video: {
            id_tweet:  null,
            id_video:  null,
            autor:  null,
            src:  null,
            url: null,
            protagonista:  null,
            descripcion:  null,
            enlace: null,
            imagen: null,
            favorito: false,
            favoritos_count: 0,
            videos_download: null,
            licencia: false
          }
        }
    },
    methods: {
      getShareURL(item) {
        var url = window.location.protocol + "//" + window.location.host + "/video/" + item.id_video;
        return encodeURIComponent(url)
      },
      share(item) {
        var self = this
        if(process.env.NODE_ENV === 'production') {
          this.$gtag.event('Compartir', {
            'event_category': 'Vídeo',
            'event_label': self.video.id_video + ". " + self.video.protagonista + ': ' + self.video.descripcion,
            'value': self.video.id_video
          })
        }
        if(item.videos_download) {
          self.$root.show_loader = true
          var videos_download = JSON.parse(item.videos_download)
          fetch(process.env.VUE_APP_API_BASE_URL + '/getVideo/' + videos_download[videos_download.length-1].file)
          .then(function(response) {
            return response.blob()
          })
          .then(function(blob) {
            var file = new File([blob], videos_download[videos_download.length-1].file, {type: 'video/mp4'});
            var filesArray = [file];

            var md = new MobileDetect(window.navigator.userAgent);

            if(navigator.canShare && navigator.canShare({ files: filesArray })) {
              if(md.os() == "iOS" && md.userAgent() == "Safari") {
                navigator.share({
                  files: filesArray
                })
                // .then(() => {
                //   console.log("Successful share ios");
                // })
                // .catch((error) => {
                //   console.log("Error sharing ios", error);
                //   self.$root.showAlert_error(error)
                // })
              }
              else {
                navigator.share({
                  text: item.descripcion,
                  files: filesArray,
                  title: 'cliptter.com',
                  url: window.location
                })
                // .then(() => {
                //   console.log("Successful share");
                // })
                // .catch((error) => {
                //   console.log("Error sharing", error);
                //   self.$root.showAlert_error(error)
                // })
              }
            }
            else {
              self.$root.showAlert_error("Tu navegador no es compatible con esta función.<br> Versiones compatibles: Chrome Android >=76; Opera Android >=54; Safari on iOS>=15; Samsung Internet >=11.0.<br>Si lo deseas puedes descargar y comparte el vídeo.")
            }
          })
          .catch(function(error) {
            console.log("Error");
            console.log(error);
            self.$root.showAlert_error(error)
          })
          .finally(function() {
            self.$root.show_loader = false
          });
        }
      },
      getImageUrl(item) {
        return process.env.VUE_APP_API_BASE_URL + '/images/thumbnail/' + item.imagen
      },
      buscar() {
        this.updatePage()
      },
      addItems: function (items) {
        if(this.infiniteScroll.page==1)
          this.items = items
        else {
          for (var i in items) {
            this.items.push(items[i])
          }
        }
      },
      getData: function() {
        if(!this.$route.params.id_video)
        {
          let self = this
          this.infiniteScroll.load = true
          this.infiniteScroll.page = this.infiniteScroll.page + 1

          if (this.infiniteScroll.page <= this.infiniteScroll.total_pages || this.infiniteScroll.page == 1) {
              axios.get(process.env.VUE_APP_API_BASE_URL + '/todos', { params: {
                page: self.infiniteScroll.page,
                buscar: self.$root.filter.buscar,
                autor: self.$root.filter.autor.user,
                tema_url: self.$root.filter.tema.tema_url,
                duracion: self.$root.filter.duracion,
                favoritos: self.$root.filter.favoritos,
                populares: self.$root.filter.populares
              }
            }).then(response => {
              this.addItems(response.data.items)
              this.infiniteScroll.total_pages = response.data.total_pages
            })
            .catch(err => {
              console.log(err)

            }).then(response => {
              this.infiniteScroll.load = false
            })
          }
          else {
              this.infiniteScroll.load = false
          }
        }
      },
      setVideo(item) {
        this.setPage('PageVideo', item.id_video)
      },
      showModalVerVideo(id_video) {
        var self = this
        axios.get(process.env.VUE_APP_API_BASE_URL + '/getInfoVideo', {
          params: {
            id_video: id_video
          }
        }).then(response => {
          this.video.id_tweet = response.data.id_tweet
          this.video.id_video = response.data.id_video
          this.video.autor = response.data.autor
          this.video.src = 'https://twitter.com/i/videos/' + response.data.id_tweet
          this.video.protagonista = response.data.protagonista
          this.video.descripcion = response.data.descripcion
          this.video.hashtags = response.data.hashtags
          this.video.url = response.data.url
          this.video.imagen = response.data.imagen
          this.video.favorito = response.data.favorito
          this.video.favoritos_count = response.data.favoritos_count
          this.video.videos_download = response.data.videos_download
          this.video.licencia = response.data.licencia
          $("#vueModalVerVideo").modal({
            show: true,
            keyboard: false,
            backdrop: 'static'
          });
        })
        .catch(err => {
          console.log("Error");
          console.log(err);
          self.$root.showAlert_error(err)
        })


      },
      showModalVerVideoClick(id_video) {
        var item = this.items.find(el => el.id_video == id_video)
        this.video.id_video = item.id_video
        this.video.autor = item.autor
        this.video.protagonista = item.protagonista
        this.video.descripcion = item.descripcion
        this.video.hashtags = item.hashtags
        this.video.imagen = item.imagen
        this.video.url = item.url
        this.video.favorito = item.favorito
        this.video.favoritos_count = item.favoritos_count
        this.video.videos_download = JSON.parse(item.videos_download)
        $("#vueModalVerVideo").modal({
            show: true,
            keyboard: false,
            backdrop: 'static'
          });
      },
      showModalEditVideo(item) {
        var self = this
        axios.get(process.env.VUE_APP_API_BASE_URL + '/getInfoVideo', {
          params: {
            id_video: item.id_video
          }
        }).then(response => {
          this.$root.tweet_detail.id_video = item.id_video
          this.$root.tweet_detail.imagen = item.imagen
          this.$root.tweet_detail.input_protagonista = item.protagonista
          this.$root.tweet_detail.input_descripcion = item.descripcion
          this.$root.tweet_detail.input_hashtags = item.hashtags
          this.$root.tweet_detail.input_temas = item.temas
          this.$root.tweet_detail.input_temas = item.temas

          this.$root.tweet_detail.videos_download = response.data.videos_download

          $("#vueModalEditVideo").modal({
            show: true,
            keyboard: false,
            backdrop: 'static'
          });
        })
        .catch(err => {
          console.log("Error");
          console.log(err);
          self.$root.showAlert_error(err)
        })


      },
      // showModalEditVideo(item) {
      //   this.$root.tweet_detail.id_video = item.id_video
      //   this.$root.tweet_detail.imagen = item.imagen
      //   this.$root.tweet_detail.input_protagonista = item.protagonista
      //   this.$root.tweet_detail.input_descripcion = item.descripcion
      //   this.$root.tweet_detail.input_hashtags = item.hashtags
      //   this.$root.tweet_detail.input_temas = item.temas
      //   $("#vueModalEditVideo").modal('show')
      // },
      setFavorito(type, id_video) {
        if(!this.isLogued()) {
          this.$root.showAlert_logueate()
        }
        else {
          let self = this
          self.$root.show_loader = true
          axios.post(process.env.VUE_APP_API_BASE_URL + '/setFavorito', {
            id_video: id_video
          }).then(response => {
            if (response.status === 200) {
              var indexItem = self.items.findIndex(el => el.id_video == id_video)
              if (response.data.action === "add") {
                self.items[indexItem].favorito = true
                self.items[indexItem].favoritos_count = response.data.favoritos_count
                this.$store.state.session.favoritos += 1
                if(type=="modal")
                {
                  self.video.favorito = true
                  self.video.favoritos_count = response.data.favoritos_count
                }
              } else if (response.data.action === "delete") {
                self.items[indexItem].favorito = false
                self.items[indexItem].favoritos_count = response.data.favoritos_count
                this.$store.state.session.favoritos -= 1
                if(type=="modal")
                {
                  self.video.favorito = false
                  self.video.favoritos_count = response.data.favoritos_count
                }
              }
            }
          })
          .catch(err => {
              console.log("Error");
              console.log(err);
              self.$root.showAlert_error(err)
          })
          .finally(() => {
            self.$root.show_loader = false
          });
        }
      },
      filtrarFavoritos() {
        if(this.isLogued()) {
          this.$root.filter.favoritos =! this.$root.filter.favoritos
        }
        else {
          this.$root.showAlert_logueate()
        }
      },
      cambiarVista() {
        if (this.$root.tipoVista.tipo=="listado") {
          this.$root.tipoVista.cols = 'col-12 padding-0'
          this.$root.tipoVista.showInfoVideo = true
        }
        else {
          this.$root.tipoVista.cols = 'col-4'
          this.$root.tipoVista.showInfoVideo = false
        }
      },
      deleteVideo() {
        let self = this
        self.$root.show_loader = true
        axios.post(process.env.VUE_APP_API_BASE_URL + '/deleteVideo', {
          id_video: self.$root.tweet_detail.id_video
        }).then(response => {
          if (response.status === 200) {
            if(response.data.error)
            {
              self.$root.tweet_detail.error.code = response.data.error
              self.$root.tweet_detail.error.message = response.data.message
            }
            else {
              var indexItem = self.items.findIndex(el => el.id_video == self.$root.tweet_detail.id_video)
              self.items[indexItem].protagonista = self.$root.tweet_detail.input_protagonista
              self.items[indexItem].descripcion = self.$root.tweet_detail.input_descripcion
              self.items[indexItem].hashtags = self.$root.tweet_detail.input_hashtags
              self.items[indexItem].temas = self.$root.tweet_detail.input_temas
              self.$root.showAlert_confirm("Video eliminado")
              $("#vueModalEditVideo").modal('hide')
              self.items.splice(indexItem, 1);
            }
          }
        })
        .catch(err => {
            console.log("Error");
            console.log(err);
            self.$root.showAlert_error(err)
        })
        .finally(() => {
          self.$root.show_loader = false
        });
      },
      editVideo() {
        let self = this
        self.$root.show_loader = true
        axios.post(process.env.VUE_APP_API_BASE_URL + '/editVideo', {
          id_video: self.$root.tweet_detail.id_video,
          input_protagonista: self.$root.tweet_detail.input_protagonista,
          input_descripcion: self.$root.tweet_detail.input_descripcion,
          input_hashtags: self.$root.tweet_detail.input_hashtags,
          input_temas: self.$root.tweet_detail.input_temas
        }).then(response => {
          if (response.status === 200) {
            if(response.data.error)
            {
              self.$root.tweet_detail.error.code = response.data.error
              self.$root.tweet_detail.error.message = response.data.message
            }
            else {
              var indexItem = self.items.findIndex(el => el.id_video == self.$root.tweet_detail.id_video)
              self.items[indexItem].protagonista = self.$root.tweet_detail.input_protagonista
              self.items[indexItem].descripcion = self.$root.tweet_detail.input_descripcion
              self.items[indexItem].hashtags = self.$root.tweet_detail.input_hashtags
              self.items[indexItem].temas = self.$root.tweet_detail.input_temas
              $("#vueModalEditVideo").modal('hide')
            }
          }
        })
        .catch(err => {
            console.log("Error");
            console.log(err);
            self.$root.showAlert_error(err)
        })
        .finally(() => {
          self.$root.show_loader = false
        });
      },
      updatePage() {
        if(this.$route.params.id_video)
        {
          this.showModalVerVideo(this.$route.params.id_video)
        }
        else {
          this.infiniteScroll.page = 0
          if(this.$root.filter.autor.user)
          {
            this.getInfoUser(this.$root.filter.autor.user)
            if(this.$route.name == 'PageAutor') {
              if(this.$root.filter.buscar) {
                this.$router.push('/autor/' + this.$root.filter.autor.user + "/" + this.$root.filter.buscar).catch(()=>{});
              }
              else {
                this.$router.push('/autor/'  + this.$root.filter.autor.user).catch(()=>{});
              }
            }
          }
          if(this.$root.filter.tema.tema_url)
          {
            this.getInfoTema(this.$root.filter.tema.tema_url)
            if(this.$route.name == 'PageTema') {
              if(this.$root.filter.buscar) {
                this.$router.push('/tema/' + this.$root.filter.tema.tema_url + "/" + this.$root.filter.buscar).catch(()=>{});
              }
              else {
                this.$router.push('/tema/' + this.$root.filter.tema.tema_url).catch(()=>{});
              }
            }
          }

          if(!this.$root.filter.autor.user && !this.$root.filter.tema.tema_url) {
            if(this.$route.name == 'PageDescubre') {
              if(this.$root.filter.buscar) {
                this.$router.push('/descubre/'+this.$root.filter.buscar).catch(()=>{});
              }
              else {
                this.$router.push('/descubre').catch(()=>{});
              }
            }
          }

          this.getData()
        }
      },
      getFileDownload(video) {
        if(video.videos_download != null) {
          var self = this
          if(process.env.NODE_ENV === 'production') {
            this.$gtag.event('Descargar', {
              'event_category': 'Vídeo',
              'event_label': self.video.id_video + ". " + self.video.protagonista + ': ' + self.video.descripcion,
              'value': self.video.id_video
            })
          }
          return process.env.VUE_APP_API_BASE_URL + '/getVideoDownload/' + video.videos_download[0].file
        }
        return null
      },
      getUrl(video) {
        if (!video.url){
            var url = new URL(window.location.href)
            return url.host + "/video/" + video.id_video
        }
        return video.url
      },
      copyToClipboard(video) {
        var self = this
        if(process.env.NODE_ENV === 'production') {
          this.$gtag.event('copyToClipboard', {
            'event_label': video.id_video + ". " + video.protagonista + ': ' + video.descripcion,
            'value': self.getUrl(video)
          })
        }
        navigator.clipboard.writeText(self.getUrl(video))
        .then(() => { this.$noty.success("Copiado!") })
        .catch((error) => { this.$noty.error(`Copy failed! ${error}`) })
      }
    },
    mounted: function () {
      if(this.$root.name=="PageFavoritos" && !this.isLogued()) {
        this.$root.showAlert_logueate()
        this.$root.setPage('PageInicio')
      }
      this.infiniteScroll.page = 0
    },
    beforeDestroy () {
    },
    beforeMounted: function () {
    },
    created() {
      this.$root.getTemas();
      //Esto lanzará el watch de filtros y sus correspondientes filtros
      if(this.$route.params.autor) { this.$root.filter.autor.user = this.$route.params.autor }
      if(this.$route.params.tema_url) { this.$root.filter.tema.tema_url = this.$route.params.tema_url }
      if(this.$route.params.buscar) { this.$root.filter.buscar = this.$route.params.buscar }
      this.updatePage()
    },
    watch: {
      //Este evento solo se lanza cuando cambio de url sin tocar los filtros de busqueda, es decir, lo que cabian filtros,
      //se lanzan por los filter pero el de id_video no esta en ningun filter
      $route (to, from){
        if(this.$route.params.id_video)
        {
          this.showModalVerVideoClick(this.$route.params.id_video)
        }
        else
        {
          this.video.id_tweet = null
          this.video.id_video = null
          this.video.autor = null
          this.video.src = null
          this.video.protagonista = null
          this.video.descripcion = null
          this.video.hashtags = null
          this.video.url = null
          this.video.enlace = null
          this.video.favorito = false
          this.video.favoritos_count = null
          $("#vueModalVerVideo").modal('hide')
          this.updatePage()
        }
      },
      '$root.filter.populares': {
        handler: function(val, oldVal) {
          // console.log('value changed from ' + oldVal + ' to ' + val);
          window.scrollTo(0, 0);
          this.updatePage()
        },
        deep: true
      },
      '$root.filter.duracion': {
        handler: function(val, oldVal) {
          // console.log('value changed from ' + oldVal + ' to ' + val);
          window.scrollTo(0, 0);
          this.updatePage()
        },
        deep: true
      },
      '$root.tipoVista': {
        handler: function(val, oldVal) {
          this.cambiarVista()
        },
        deep: true
      }
    }
  }
</script>
