<template>
  <div id="vueModalAddVideo" class="modal fade" role="dialog" ref="vueModalAddVideo">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content" style="background-color: #15202b">
        <div class="modal-header" style="color: white; border-bottom-color: #3a5061">
          <h4 class="modal-title">Añadir vídeo</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" style="color: white">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit="checkForm">
            <div class="file-upload">
              <div>
                <div class="text-center p-2">
                  <ul class="nav nav-tabs nav-justified">
                    <li class="nav-item">
                      <a style="color:#FFF;" class="nav-link" @click.prevent="setActive('video')" :class="{ active: isActive('video') }" href="#video">
                        <font-awesome-icon icon="video"/>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a style="color:#FFF;" class="nav-link" @click.prevent="setActive('tweet')" :class="{ active: isActive('tweet') }" href="#tweet">
                        <font-awesome-icon :icon="['fab', 'twitter']" />
                      </a>
                    </li>
                  </ul>
                  <div v-show="$refs.upload && $refs.upload.dropActive && activeItem == 'video'" class="drop-active">

                  </div>
                  <div class="file-edit" v-show="!loader_convirtiendo && ((files.length && edit) || (activeItem != 'video' && tweet_detail.id_tweet != null))">
                    <div class="file-edit-image">
                      <video ref="plyr" crossorigin id="player_add" controls playsinline></video>
                    </div>
                  </div>
                  <div class="tab-content py-3" id="myTabContent" style="">
                    <div class="tab-pane fade" :class="{ 'active show': isActive('video') }" id="video">
                      <div v-if="!edit">
                        <label for="file">
                          <div class="rounded-circle" style="border:1px solid #CCC; margin:0 auto; display:flex; justify-content:center;align-items: center;">
                            <div v-if="!loader_convirtiendo">
                              <h3>Arrastra el video a subir</h3>
                            </div>
                            <div v-else="loader_convirtiendo" style="text-align:center">
                              <SemipolarSpinner
                            	:animation-duration="1000"
                              :size="100"
                              :color="'#00a478'"
                            	/>
                              </div>
                          </div>
                        </label>
                        <div v-if="files.length > 0">
                          <div class="progress" v-if="(files[0].active || files[0].progress !== '0.00')">
                            <div :class="{'progress-bar': true, 'progress-bar-striped': true, 'bg-danger': files[0].error, 'progress-bar-animated': files[0].active}" role="progressbar" :style="{width: files[0].progress + '%'}">
                              <span v-if="files[0].progress != 100">{{files[0].progress}}%</span>
                              <span v-else>{{files[0].progress}}% - Convirtiendo...</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-center p-2">
                        <file-upload
                          extensions="mp4,mov,avi"
                          accept="video/mp4,video/quicktime,video/x-msvideo"
                          name="video_file"
                          class="btn btn-primary"
                          :size="1024 * 1024 * 64"
                          :post-action="put_action()"
                          :drop="!edit"
                          v-model="files"
                          @input-filter="inputFilter"
                          @input-file="inputFile"
                          :class="{'disabled': loader_convirtiendo}"
                          :disabled="loader_convirtiendo"
                          ref="upload">
                          <span v-if="edit" >Editar video</span>
                          <span v-else="!edit">Subir video</span>
                        </file-upload>
                      </div>
                    </div>
                    <div class="tab-pane fade" :class="{ 'active show': isActive('tweet') }" id="tweet">
                      <div v-if="file_name == '' || file_name == null">
                        <div class="rounded-circle" style="border:1px solid #CCC; margin:0 auto; display:flex; justify-content:center;align-items: center;">
                          <div v-if="!loader_convirtiendo">
                            <h3>Pega el enlace del tweet:</h3>
                          </div>
                          <div v-else="loader_convirtiendo" style="text-align:center">
                            <SemipolarSpinner
                            :animation-duration="1000"
                            :size="100"
                            :color="'#00a478'"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <input type="text" class="form-control inputEstilo" id="inputEnlace" name="url" placeholder="ej. https://twitter.com/cliptter/status/831880645035061249" v-model="tweet_detail.input_enlace">
                      </div>
                      <button class="btn btn-primary"
                      :class="{'disabled': loader_convirtiendo}"
                      :disabled="loader_convirtiendo"
                      type="button" @click="addVideo()">Procesar</button>

                    </div>
                  </div>
                </div>
              </div>


              <div style="clear:both;"></div>
              <div style="font-size:12px">
                <div class="form-group" style="display: flex;align-items: center;">
                  <input v-model="inputCondicionesLegales" required="required" type="checkbox" class="" id="inputCondicionesLegales" name="inputCondicionesLegales">
                  <label for="inputCondicionesLegales" style="margin-bottom:auto; margin-left:10px; margin-top:-1px;">He leido y acepto las <a style="color:#FFF; text-decoration:underline;" href="/legal" target="_blank">condiciones legales</a></label>
                </div>
                <div class="form-group" style="display: flex;" v-if="activeItem == 'video'">
                  <input v-model="inputCondicionesAutor" required="required" type="checkbox" class="" id="inputCondicionesAutor" name="inputCondicionesAutor">
                  <label for="inputCondicionesAutor" style="margin-bottom:auto; margin-left:10px; margin-top:-5px;">Tengo derechos del autor del video / El video no dispone de derechos de autor</label>
                </div>
                <div class="form-group" style="display: flex;" v-if="activeItem == 'video'">
                  <input v-model="inputCondicionesMarcaAgua" required="required" type="checkbox" class="" id="inputCondicionesMarcaAgua" name="inputCondicionesMarcaAgua">
                  <label for="inputCondicionesMarcaAgua" style="margin-bottom:auto; margin-left:10px; margin-top:-5px;">Acepto que se incluya una marca de agua sobre el video</label>
                </div>
              </div>
              <br>
            </div>

            <!-- Parte 2 del formulario -->
            <div class="formClip">
              <div class="form-group">
                <label for="inputProtagonista">Protagonista:</label>
                <input autocomplete="off" required="required" type="text" class="form-control inputEstilo" id="inputProtagonista" name="inputProtagonista"
                  placeholder="¿Quién aparece en el vídeo?" v-model="tweet_detail.input_protagonista">
              </div>
              <div class="form-group">
                <label for="inputDescripcion">Descripción:</label>
                <textarea autocomplete="off" required="required" class="form-control inputEstilo" rows="3" id="inputDescripcion" name="inputDescripcion"
                  placeholder="Escribe lo que se dice o sucede en el vídeo" style="border-radius: 10px" v-model="tweet_detail.input_descripcion"></textarea>
              </div>
              <div class="form-group">
                <label for="inputTemas">Temas:</label>
                <select ref="inputTemas" class="form-control" multiple="multiple" id="inputTemas" name="inputTemas" v-model="tweet_detail.input_temas" v-select2>
                  <option v-for="tema in $root.temas" :key="tema.id_tema" :data-index="tema.id_tema" :value="tema.id_tema">{{tema.tema}}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="inputHashtags">Hashtags:</label>
                <input autocomplete="off" type="text" class="form-control inputEstilo" id="inputHashtags" name="inputHashtags"
                  placeholder="Añade etiquetas para que sea más fácil encontrarlo" v-model="tweet_detail.input_hashtags">
              </div>
              <button type="submit" class="btn btn-primary"
                style="background-color: #00a478; border-color: #00a478; float: right; border-radius: 50px; font-weight: bold">Añadir</button>
            </div>
          </form>
        </div>
      </div>

    </div>
  </div>
</template>



<script>
import axios from "axios"
// import VuePlyr from 'vue-plyr'
// import 'vue-plyr/dist/vue-plyr.css'
import FileUpload from 'vue-upload-component'
import { SemipolarSpinner } from 'epic-spinners'

import Plyr from 'plyr';
import 'plyr/dist/plyr.css'

export default {
  name: "ModalAddVideo",
  data: () => {
      return {
        last_error: "",
        files: [],
        edit: false,
        file_name: null,
        loader_convirtiendo: false,
        activeItem: 'video',
        last_input_enlace: '',
        inputCondicionesLegales: false,
        inputCondicionesAutor: false,
        inputCondicionesMarcaAgua: false
      }
  },
  props: ["tweet_detail"],
  components: {
    FileUpload,
    SemipolarSpinner
  },
  destroyed() {
    this.removePlayer();
  },
  mounted: function () {
    $(this.$refs.vueModalAddVideo).on("hidden.bs.modal", this.closeModalAddVideo)
    $(this.$refs.vueModalAddVideo).on("show.bs.modal", this.showModalAddVideo)
    this.$root.getTemas()

  },
  methods: {
    addVideo() {

      if(this.tweet_detail.input_enlace != this.last_input_enlace && this.tweet_detail.last_input_enlace!='') {
        this.last_input_enlace = this.tweet_detail.input_enlace

        let self = this
        // self.$root.show_loader = true
        this.loader_convirtiendo = true
        axios.post(process.env.VUE_APP_API_BASE_URL + '/addVideoTwitter', {
          input_enlace: self.tweet_detail.input_enlace
        }).then(response => {
          if (response.status === 200) {
            if(response.data.error)
            {
              this.$root.showAlert_error("Ha ocurrido un error: " + response.data.message + " - " +response.data.error)
            }
            else {
              self.file_name = response.data.file_name
              self.tweet_detail.id_tweet = response.data.id_tweet
              self.tweet_detail.url = response.data.url
              self.tweet_detail.autor = response.data.autor
              self.tweet_detail.error.code = null
              self.tweet_detail.error.message = null
              if(this.player)
                this.player.destroy();
              this.player = new Plyr('#player_add');
              this.player.source = { type: 'video', title: '', sources: [{ src: process.env.VUE_APP_API_BASE_URL + '/getVideoTmp/' + this.file_name, type: 'video/mp4' }] };
            }
          }
        })
        .catch(err => {
            console.log("Error");
            console.log(err);
            self.$root.showAlert_error(err)
            self.loader_convirtiendo = false
        })
        .finally(() => {
          this.loader_convirtiendo = false
        });
      }
      else {
        this.$root.showAlert_error("No es posible procesar el mismo video")
      }
    },
    isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      if(this.loader_convirtiendo) {
        this.$root.showAlert_error("No puedes cambiar de opcion mientras se está procesando el video")
      }
      else {
        if(menuItem!=this.activeItem && (this.file_name!="" && this.file_name != null)) {
          var self = this
          $.confirm({
            title: 'Confirmación',
            theme: 'dark',
            content: 'Si continuas, el video procesado se perderá',
            type: 'blue',
            buttons: {
              confirm: {
                  text: "Aceptar",
                  btnClass: 'btn-primary',
                  keys: ['enter'],
                  action: function(){
                    self.activeItem = menuItem
                    if (self.activeItem == "video") {
                      $("#video_file").prop('required',true);
                    }
                    else {
                      $("#video_file").prop('required',false);
                    }
                    if (menuItem == "video") {
                      self.files = []
                      self.edit = false
                      self.file_name = null,
                      self.loader_convirtiendo = false
                      self.tweet_detail.input_enlace = ""
                      self.tweet_detail.id_tweet = null
                      $("#video_file").val("");
                    }
                    else {
                      self.files = []
                      self.edit = false
                      self.file_name = null,
                      self.loader_convirtiendo = false
                      $("#video_file").val("");
                    }
                  }
              },
              cancel: function () {

              },
             }
          });
        }
        else {
          this.activeItem = menuItem
          if (this.activeItem == "video") {
            $("#video_file").prop('required',true);
          }
          else {
            $("#video_file").prop('required',false);
          }
        }
      }
    },
    select2_temas() {
      $(this.$refs.inputTemas).val("")
      $(this.$refs.inputTemas).select2({
        multiple: true,
        tags: "true",
        maximumSelectionLength: 3,
        language: {
          maximumSelected: function (e) {
              var t = "Solo puedes seleccionar un máximo de " + e.maximum + " temas";
              return t;
          }
        }
      });
    },
    checkForm: function (e) {
      e.preventDefault();
      if (this.activeItem == "tweet" && !this.loader_convirtiendo && (this.file_name == "" || this.file_name == null)) {
          this.$root.showAlert_confirm("Debes procesar primero el video completando el enlace del tweet")
      }
      else {
        if (this.loader_convirtiendo) {
          this.$root.showAlert_confirm("Su video se está procesando, espere a que finalice el proceso")
        }
        else {
          var self = this
          this.$root.addVideoUpload(this.file_name, this.activeItem).then((response) => {
            if (response.status === 200) {
              if(response.data.error){
                self.$root.showAlert_error(response.data.message)
              }
              else
              {
                $("#vueModalAddVideo").modal('hide')

                //Updateamos solo si venimos de PageDescubre
                if(self.$parent.updatePage)
                  self.$parent.updatePage()
              }
            }
          })
          .catch(err => {
              console.log("Error");
              console.log(err);
              self.$root.showAlert_error(err)
          })
          .finally(() => {
            self.$root.show_loader = false
          });
        }
      }
     },
    removePlayer() {
      if (!this.player) {
        return;
      }
      this.player.pause();
      clearInterval(this.intervalId);

    },
    showModalAddVideo() {
      if(this.player)
        this.player.destroy();
      $("#video_file").prop('required',true);
      this.select2_temas();
    },
    closeModalAddVideo() {
      this.removePlayer();
      this.tweet_detail.id_video =  null
      this.tweet_detail.id_tweet =  null
      this.tweet_detail.imagen =  null
      this.tweet_detail.duracion =  null
      this.tweet_detail.url =  null
      this.tweet_detail.autor = null
      this.tweet_detail.input_enlace =  null
      this.tweet_detail.input_protagonista = ""
      this.tweet_detail.input_descripcion = null
      this.tweet_detail.input_hashtags = null
      this.tweet_detail.input_temas = []
      this.tweet_detail.error.code = null
      this.tweet_detail.error.message = null
      this.inputCondicionesLegales = false
      this.inputCondicionesAutor = false
      this.inputCondicionesMarcaAgua = false

      this.last_input_enlace = ""

      this.files = []
      this.edit = false
      this.file_name = null,
      this.loader_convirtiendo = false
      $("#video_file").val("");
    },
    editSave() {
     this.edit = false
   },
   inputFile(newFile, oldFile, prevent) {
     if (newFile && oldFile) {
          // Update file
          if (newFile.error !="") {
            console.log('error', newFile.error, newFile)
            this.loader_convirtiendo = false
            this.$root.showAlert_error("Ha ocurrido un error: " + newFile.error)
            this.files = []
            this.edit = false
            this.file_name = null,
            $("#video_file").val("");
            return
          }

          // Start upload
          if (newFile.active !== oldFile.active) {
            this.edit = false
            $("#video_file").prop('required',true);
            //console.log('Start upload', newFile.active, newFile)
            // min size
            if (newFile.size >= 0 && newFile.size < 100 * 1024) {
              newFile = this.$refs.upload.update(newFile, {error: 'El video debe pesar al menos 100Kb'})
            }


            var videotmp = document.createElement('video');
            videotmp.preload = 'metadata';
            videotmp.onloadedmetadata = ()=> {
              window.URL.revokeObjectURL(videotmp.src);
              var duration = videotmp.duration;
              if (parseInt(duration) < 5 || parseInt(duration) > 120) {
                newFile = this.$refs.upload.update(newFile, {error: 'Solo se permiten videos con una duración de entre 5 y 120 segundos'})
                //this.$root.showAlert_error("Solo se permiten videos con una duración de entre 5 y 120 segundos")
              }
              videotmp.remove()
            }
            var file = this.$refs.upload.get(newFile.id)
            videotmp.src = newFile.url;

          }

          // Upload progress
          if (newFile.progress !== oldFile.progress) {
            console.log('progress', newFile.progress, newFile)
            if (newFile.progress==100)
              this.loader_convirtiendo = true
          }

          // Uploaded successfully
          if (newFile.success !== oldFile.success) {
            //console.log('success', newFile.success, newFile)
            if(newFile.response.message && newFile.response.error) {
              newFile = this.$refs.upload.update(newFile, {error: newFile.response.message})
            }
            else {
              this.edit = true
              this.file_name = newFile.response.file_name
              this.loader_convirtiendo = false
              if(this.player)
                this.player.destroy();
              this.player = new Plyr('#player_add');
              this.player.source = { type: 'video', title: '', sources: [{ src: process.env.VUE_APP_API_BASE_URL + '/getVideoTmp/' + this.file_name, type: 'video/mp4' }] };
              $("#video_file").prop('required',false);
            }
          }


        }

        // if (!newFile && oldFile) {
        //   // Remove file
        //   axios.post(process.env.VUE_APP_API_BASE_URL + '/deleteVideoTmp', {
        //     file: oldFile.response.file_name
        //   }).then(response => {
        //
        //   })
        //   .catch(err => {
        //     console.log("Error");
        //     console.log(err);
        //     self.$root.showAlert_error(err)
        //   })
        //   .finally(() => {
        //   });
        // }

        // Automatic upload
        if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
          if (!this.$refs.upload.active) {
            this.$refs.upload.active = true
          }
        }
   },
   inputFilter(newFile, oldFile, prevent) {
     if (newFile && !oldFile) {
       if (!/\.(mp4|mp5|mov|avi)$/i.test(newFile.name)) {
         this.alert('Your choice is not a video')
         return prevent()
       }
     }
     if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
       newFile.url = ''
       let URL = window.URL || window.webkitURL
       if (URL && URL.createObjectURL) {
         newFile.url = URL.createObjectURL(newFile.file)
       }
     }
   },
   put_action() {
     return process.env.VUE_APP_API_BASE_URL + '/uploadVideo'
   }
 }
}
</script>


<style>
.file-upload .rounded-circle {
  width: 200px;
  height: 150px;
}
.text-center .btn {
  margin: 0 .5rem
}
.file-edit-image {
  max-width: 100%
}
.drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
}
.drop-active h3 {
  margin: -.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}

.div_condiciones_tecnicas {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  display: none;
}

.nav-link.active {
  background-color: initial !important;
}
#myTabContent {
  border: 1px solid #FFF;
  border-top:none;
  padding: 5px;
}

.nav-link {
  border-bottom: 1px solid #dee2e6  !important;
}

.nav-link.active {
  border-bottom:none !important;
}

.nav-tabs {
  border-bottom: none !important;
}

.nav-link.active svg {
  color: #1DA1F2;
}

#inputEnlace {
  margin-top: 10px;
}

.file-edit {
  border: 1px solid;
  border-bottom: none;
  border-top: 1px;
  padding-top: 5px;
}
</style>
