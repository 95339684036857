/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		session: {
			token: null,
			id_user: null,
			suscripcion: null,
			user: null,
			name: null,
			avatar: null,
			banner: null,
			admin: null,
			videos: null,
			favoritos: null,
			videos_publicidad: 0,
			nuevas_condiciones:0
		}
	},
	mutations: {
		set_token: (state, newValue) => {
	    state.session.token = newValue
	  },
		set_id_user: (state, newValue) => {
	    state.session.id_user = newValue
	  },
		set_paypal_token: (state, newValue) => {
	    state.session.suscripcion = newValue
	  },
		set_user: (state, newValue) => {
	    state.session.user = newValue
	  },
		set_name: (state, newValue) => {
	    state.session.name = newValue
	  },
		set_avatar: (state, newValue) => {
	    state.session.avatar = newValue
	  },
		set_banner: (state, newValue) => {
	    state.session.banner = newValue
	  },
		set_admin: (state, newValue) => {
	    state.session.admin = newValue
	  },
		set_videos: (state, newValue) => {
	    state.session.videos = newValue
	  },
		set_favoritos: (state, newValue) => {
	    state.session.favoritos = newValue
	  },
		set_videos_publicidad: (state, newValue) => {
	    state.session.videos_publicidad = newValue
	  },
		set_nuevas_condiciones: (state, newValue) => {
	    state.session.nuevas_condiciones = newValue
	  }
	},
	actions: {
		setToken({ commit }, obj) {
			commit ('set_token', obj)
		},
		setId_user({ commit }, obj) {
			commit ('set_id_user', obj)
		},
		setPaypalToken({ commit }, obj) {
			commit ('set_paypal_token', obj)
		},
		setUser({ commit }, obj) {
			commit ('set_user', obj)
		},
		setName({ commit }, obj) {
			commit ('set_name', obj)
		},
		setAvatar({ commit }, obj) {
			commit ('set_avatar', obj)
		},
		setBanner({ commit }, obj) {
			commit ('set_banner', obj)
		},
		setAdmin({ commit }, obj) {
			commit ('set_admin', obj)
		},
		setVideos({ commit }, obj) {
			commit ('set_videos', obj)
		},
		setFavoritos({ commit }, obj) {
			commit ('set_favoritos', obj)
		},
		setVideos_publicidad({ commit }, obj) {
			commit ('set_videos_publicidad', obj)
		},
		setNuevas_condiciones({ commit }, obj) {
			commit ('set_nuevas_condiciones', obj)
		}
	},
	getters: {
		token: state => state.session.token,
		id_user: state => state.session.id_user,
		suscripcion: state => state.session.suscripcion,
		user: state => state.session.user,
		name: state => state.session.name,
		avatar: state => state.session.avatar,
		banner: state => state.session.banner,
		admin: state => state.session.admin,
		videos: state => state.session.videos,
		favoritos: state => state.session.favoritos,
		videos_publicidad: state => state.session.videos_publicidad,
		nuevas_condiciones: state => state.session.nuevas_condiciones
	},
	plugins: [createPersistedState()]
})
