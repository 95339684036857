<template>
  <div>
    <!-- <Adsense
        data-ad-client="ca-pub-4360409103851792"
        data-ad-slot="slot_id">
    </Adsense>

    <InArticleAdsense
        data-ad-client="ca-pub-4360409103851792"
        data-ad-slot="slot_id">
    </InArticleAdsense>

    <InFeedAdsense
        data-ad-layout-key="-fg+5n+6t-e7+r"
        data-ad-client="ca-pub-4360409103851792"
        data-ad-slot="slot_id">
    </InFeedAdsense> -->
    <NuevasCondiciones></NuevasCondiciones>
    <MenuMovil></MenuMovil>
      <!--Contenido principal-->
      <div class="container">
        <div class="row" style="min-height: 100vh;">
          <!-- Menú lateral solo PC-->
          <MenuPc :filter="$root.filter"></MenuPc>
          <!-- Barra central-->
          <div class="col-12 col-md-8 col-lg-6" style="padding-bottom: 40px">
            <div class="fijar-barra">
              <NavTop></NavTop>
            </div>
            <div class="row" style="padding: 4px;">
              <div class="col-12" style="padding: 0">
                <video src="/static/videos/promo.mp4" style="width: 100%" autoplay muted loop playsinline></video>
              </div>


              <!--BUSCADOR-->
              <div class="col-12" style="padding-left: 0; padding-right: 0">
                  <div class="tarjeta-promo">

                    <div class="tarjeta-promo-titulo"><div class="logo-buscador"><img src="@/assets/images/isologo.png" style="height: 50px" /></div></div>

                    <div class="tarjeta-promo-texto" style="margin-bottom: -10px">Busca palabras clave para un mejor resultado.</div>
                    <div style="text-align: center; padding: 10px"><input type="text" class="form-control" id="buscar" aria-describedby="buscar" placeholder="Buscar..." style="height: 60px; font-size: 22px; padding-left: 25px" ></div>
                  </div>
                </div>

                <!-- <div id="snackbar" style="z-index: 9999">Link copiado</div> -->

                  <!-- Vídeos recientes -->

                <div class="col-12" style="font-weight: bold; padding-left: 5px; margin-top: 10px">Vídeos más recientes</div>
                <div class="col-3" v-for="item in videos_recientes" :key="'videos_recientes_' + item.id_video" :data-index="item.id_video">
                  <div class="div-cuadrado-video" :style="{ 'background-image': 'url(' + getImageUrl(item) + ')'}" @click="setPage('PageVideo',item.id_video)"></div>
                </div>

            </div>
          </div>

          <!--Barra lateral derecha, solo PC-->
          <div class="d-none d-md-block col-md-3 col-lg-4" style="border-left: 1px solid #38444d; padding-top: 10px">
            <div style="position: sticky; top: 10px">
              <Tendencias/>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal para añadir-->
      <ModalAddVideo :tweet_detail="$root.tweet_detail"></ModalAddVideo>
  </div>
</template>

<script>
import PageMixin from './PageMixin'
import axios from "axios"

export default {
    name: "PageInicio",
    mixins: [PageMixin],
    data: () => {
        return {
          videos_recientes: []
        }
    },
    methods: {
      getImageUrl(item) {
        return process.env.VUE_APP_API_BASE_URL + '/images/thumbnail/' + item.imagen
      },
      setFavorito(type, id_video) {
        if(!this.isLogued()) {
          this.$root.showAlert_logueate()
        }
        else {
          let self = this
          self.$root.show_loader = true
          axios.post(process.env.VUE_APP_API_BASE_URL + '/setFavorito', {
            id_video: id_video
          }).then(response => {
            if (response.status === 200) {
              if (response.data.action === "add") {
                this.$store.state.session.favoritos += 1
              } else if (response.data.action === "delete") {
                this.$store.state.session.favoritos -= 1
              }
            }
          })
          .catch(err => {
              console.log("Error");
              console.log(err);
              self.$root.showAlert_error(err)
          })
          .finally(() => {
            self.$root.show_loader = false
          });
        }
      },
      getDataInicio() {
        let self = this
        self.$root.show_loader = true
        axios.get(process.env.VUE_APP_API_BASE_URL + '/dataInicio').then(response => {
          this.videos_recientes = response.data.videos_recientes
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          self.$root.show_loader = false
        });

      }
    },
    mounted: function () {
      //Cosas de bootstrap, el video realmente se carga en PageDescubre pero si vuelves con el botón de atrás del navegador,
      //deja un div "modal-backdrop fade show" bloqueando todo el contenido y totalmente negro
      $(".modal-backdrop").remove()
      $("body").removeClass("modal-open")
      let self = this
      $('#buscar').keypress(function (e) {
          // $buscar = $("#inputBuscar").val();
          if (e.which === 13) {//Enter key pressed
            self.$root.filter.buscar = $("#buscar").val()
            self.$root.setPage('PageDescubre', self.$root.filter.buscar)
          }
      });

      this.getDataInicio()

    }
  }
</script>
