<template>
  <div>
    <div class="tarjeta">
      <div class="tarjeta-titulo">Tendencias</div>
      <div v-if="$root.tendencias.length > 0">
        <div v-for="tendencia in $root.tendencias" :key="tendencia.id_tendencia" :data-index="tendencia.id_tendencia" class="tarjeta-opcion" @click="filtrarTendencia(tendencia.tendencia)">{{tendencia.tendencia}}</div>
      </div>
      <div v-else>
        --
      </div>
    </div>
    <div class="opcion-menu">
      <div class="row">
        <div style="cursor:pointer;" class="col-12" @click="$root.setPage('PageCookies')">Cookies</div>
        <div style="cursor:pointer;margin-top:10px;" class="col-12" @click="$root.setPage('PagePrivacidad')">Política de privacidad</div>
        <div style="cursor:pointer;margin-top:10px;" class="col-12" @click="showNuevasCondiciones()">Novedades</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
    name: "Tendencias",
    data: () => {
        return {

        }
    },
    methods: {
      filtrarTendencia(tendencia) {
        $('#sidebar').removeClass('active');
        this.$root.setPage("PageDescubre", tendencia)
      },
      showNuevasCondiciones() {
        $('#vueModalNuevasCondiciones').modal({
            show: true,
            keyboard: false,
            backdrop: 'static'
          });
      }
    },
    mounted: function () {
      this.$root.getTendencias()
    }
}
</script>
