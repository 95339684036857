<template>
  <div>
    <!-- Modal vídeo-->
    <div class="modal fade modal-video"  data-keyboard="false" id="vueModalVerVideo" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" ref="vueModalVerVideo">
      <div class="modal-dialog modal-dialog-centered modal-dialog-video" role="document">
        <div class="cortinilla" v-if="show_loader"></div>
        <div class="modal-content modal-content-video" :class="{'ventana-bloqueada': show_loader}">
          <div class="modal-header modal-header-mobile" style="border-bottom: 0; padding-top: 8px; padding-bottom: 5px">
            <div style="width: 50px; border-radius: 50px; color: yellow; font-weight: bold; cursor: pointer" @click="$parent.setFavorito('modal', video.id_video)">
                  <img style="width: 20px; float: left; margin-top: 3px; margin-right: 5px" :src="video.favorito > 0 ? icon_favsi : icon_favno">
                  <div style="color:#FFF;"> {{video.favoritos_count}}</div>
            </div>
            <button type="button" class="close" aria-label="Close" @click="closeModalVerVideoButton">
              <span aria-hidden="true" style="color: white">&times;</span>
            </button>
          </div>

          <div class="modal-body modal-padding-top-mobile" style="padding: 0">
            <div id="div_tweet">
              <!-- crossorigin -->
              <video ref="plyr" crossorigin id="player" controls playsinline></video>
              <!-- <vue-plyr ref="plyr" :options="plyr_options" >
                <video
                  controls
                  playsinline>
                    <source v-if="video.videos_download"
                    :src="getFile()"
                    type="video/mp4"/>
                </video>
              </vue-plyr> -->
            </div>
          </div>
          <div class="modal-footer modal-footer-video" style="padding: 5px; border-top: 0; padding-top: 0">
            <div class="modal-padding-mobile" style="color: white; margin-bottom: 10px; background-color: #192734; padding: 10px">
              <div style="font-size: 18px; font-weight: bold; margin-bottom: 3px">{{video.autor}}</div>
              <div style="font-size: 18px; font-weight: bold; margin-bottom: 3px">{{video.protagonista}}</div>
              <div style="font-size: 16px;">{{video.descripcion}}</div>
            </div>
            <div class="tarjeta-promo modal-footer-mobile" style="margin-left: 0">
              <div class="row" style="margin-left: 0; margin-right: 0">
                <div class="col-12" style="padding-left: 0; padding-right: 0">
                  <div class="form-group">
                    <div style="display:flex-inline; flex-direction:row">
                      <input ref="url" type="text" class="form-control form-control-sm" :value="getUrl()">
                      <span @click="copyToClipboard()" style="right: 20px;position: absolute;top: 8px; cursor:pointer;">
                        <font-awesome-icon icon="copy"/>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            <div class="row" style="color: white; margin-left: 0">
              <div class="col-6" style="padding-right: 7px; padding-left: 0" @click="share()">
                <div
                  style="padding: 10px; width: 100%; text-align: center; border: 1px solid #00a478; border-radius: 50px; background-color:#00a478; font-weight: bold; cursor: pointer">
                  Compartir</div>
              </div>
              <div class="col-6" style="padding-left: 7px; padding-right: 14px">
                <a style="text-decoration:none" target="_blank" class="popup" :href="getFileDownload(video)">
                  <div style="padding: 10px; width: 100%; text-align: center; border: 1px solid #1da1f2; border-radius: 50px; background-color: yellow; font-weight: bold; cursor: pointer; color: #000">Descargar</div>
                </a>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import icon_favsi from '@/assets/images/favsi.png'
import icon_favno from '@/assets/images/favno.png'
import VideoPublicidad from './VideoPublicidad.vue'

import Plyr from 'plyr';
import 'plyr/dist/plyr.css'

import 'vuejs-noty/dist/vuejs-noty.css'

var MobileDetect = require('mobile-detect');

export default {
    metaInfo() {
      if(this.video.id_video) {
        return {
          meta: [
            { vmid: 'og:video', property: 'og:video', content: "" },
            { vmid: 'twitter:player', property: 'twitter:player', content: "" },
            { vmid: 'twitter:player:width', property: 'twitter:player:width', content: '640' },
            { vmid: 'twitter:player:height', property: 'twitter:player:height', content: '480' },
            { vmid: 'twitter:card', property: 'twitter:card', content: 'player' },
            { vmid: 'twitter:title', property: 'twitter:title', content: this.video.titulo },
            { vmid: 'twitter:description', property: 'twitter:description', content: this.video.descripcion },
            { vmid: 'twitter:image', property: 'twitter:image', content: this.getImageUrl(this.video) },
          ]
        }
      }
      return false;
    },
    name: "ModalVerVideo",
    props: ["video"],
    components: {
      VideoPublicidad
    },
    data: () => {
        return {
          show_video_publicidad: false,
          customMeta: [],
          show_loader: false,
          icon_favsi,
          icon_favno,
          avatar: null,
          video_publicidad: null,
          intervalId: null
        }
    },
    methods: {
      removePlayer() {
        if (!this.player) {
          return;
        }
        this.player.pause();
        clearInterval(this.intervalId);
      },
      showModalVerVideo() {
        if(this.player)
          this.player.destroy();
        this.player = new Plyr('#player', {
          debug: false,
          ads: {
            enabled: true,
            // tagUrl: 'https://www.videosprofitnetwork.com/watch.xml?key=c9180de1fbc68290e77b1a57da89614f'
            tagUrl: 'https://syndication.exdynsrv.com/splash.php?idzone=3591147'
          }
        });
        this.player.source = { type: 'video', title: this.video.descripcion, sources: [{ src: this.getFile(), type: 'video/mp4' }] };
        this.player.poster = this.getImageUrl(this.video)
      },
      closeModalVerVideo() {
        this.removePlayer();
        this.show_loader = false
        if(window.previousUrl == null)
          this.$root.setPage('PageInicio')
      },
      closeModalVerVideoButton() {
        this.removePlayer();
        if(window.previousUrl == null)
          this.$root.setPage('PageInicio')
        else
          this.$router.go(-1)
      },
      closeModalAndGoSuscribete() {
        $("body").removeClass("modal-open");
        this.removePlayer();
        $("#vueModalVerVideo").modal('hide')
        this.show_loader = false
        this.$root.setPage('PageSuscribete')
      },
      cerrarCargador() {
        this.show_loader = false
      },
      getImageUrl(video) {
        return process.env.VUE_APP_API_BASE_URL + '/images/thumbnail/' + video.imagen
      },
      getFileDownload(video) {
        if(video.videos_download != null) {
          var self = this
          if(process.env.NODE_ENV === 'production') {
            this.$gtag.event('Descargar', {
              'event_category': 'Vídeo',
              'event_label': self.video.id_video + ". " + self.video.protagonista + ': ' + self.video.descripcion,
              'value': self.video.id_video
            })
          }
          return process.env.VUE_APP_API_BASE_URL + '/getVideoDownload/' + video.videos_download[0].file
        }
        return null
      },
      getFile() {
        if(this.video.videos_download)
          return process.env.VUE_APP_API_BASE_URL + '/getVideo/' + this.video.videos_download[this.video.videos_download.length-1].file
        return null
      },
      getShareURL() {
        if(this.video.videos_download)
          return encodeURIComponent(process.env.VUE_APP_API_BASE_URL + '/getVideo/' + this.video.videos_download[this.video.videos_download.length-1].file)
        return ''
      },
      iOSversion() {
        let d, v;
        if (/iP(hone|od|ad)/.test(navigator.platform)) {
          v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
          d = {
            status: true,
            version: parseInt(v[1], 10) ,
            info: parseInt(v[1], 10)+'.'+parseInt(v[2], 10)+'.'+parseInt(v[3] || 0, 10)
          };
        }else{
          d = {status:false, version: false, info:''}
        }
        return d;
      },
      share() {
        // let iosVer = this.iOSversion();
        // if (iosVer.status) {
        //   alert('iOS : '+iosVer.info+', version : '+iosVer.version);
        //   //ex. IOS 11.2.6, version 11
        // }else{
        //   alert('not iPhone or iPad');
        // }

        var self = this
        if(process.env.NODE_ENV === 'production') {
          this.$gtag.event('Compartir', {
            'event_category': 'Vídeo',
            'event_label': self.video.id_video + ". " + self.video.protagonista + ': ' + self.video.descripcion,
            'value': self.video.id_video
          })
        }
        self.$root.show_loader = true
        fetch(process.env.VUE_APP_API_BASE_URL + '/getVideo/' + this.video.videos_download[this.video.videos_download.length-1].file)
        .then(function(response) {
          return response.blob()
        })
        .then(function(blob) {
          var file = new File([blob], self.video.videos_download[self.video.videos_download.length-1].file, {type: 'video/mp4'});
          var filesArray = [file];

          var md = new MobileDetect(window.navigator.userAgent);

          if(navigator.canShare && navigator.canShare({ files: filesArray })) {
            if(md.os() == "iOS" && md.userAgent() == "Safari") {
              navigator.share({
                files: filesArray
              })
              // .then(() => {
              //   console.log("Successful share ios");
              // })
              // .catch((error) => {
              //   console.log("Error sharing ios", error);
              //   self.$root.showAlert_error(error)
              // })
            }
            else {
              navigator.share({
                files: filesArray,
                text: self.video.descripcion,
                title: 'cliptter.com',
                url: window.location
              })
              // .then(() => {
              //   console.log("Successful share");
              // })
              // .catch((error) => {
              //   console.log("Error sharing", error);
              //   self.$root.showAlert_error(error)
              // })
            }
          }
          else {
            self.$root.showAlert_error("Tu navegador no es compatible con esta función.<br>Versiones compatibles: Chrome Android >=76; Opera Android >=54; Safari on iOS>=15; Samsung Internet >=11.0.<br>Si lo deseas puedes descargar y comparte el vídeo.")
          }
        })
        .catch(function(error) {
          console.log("Error");
          console.log(error);
          self.$root.showAlert_error(error)
        })
        .finally(function() {
          self.$root.show_loader = false
        });
      },
      getUrl() {
        if (!this.video.url)
          return window.location.href
        return this.video.url
      },
      copyToClipboard() {
        var self = this
        if(process.env.NODE_ENV === 'production') {
          this.$gtag.event('copyToClipboard', {
            'event_label': self.video.id_video + ". " + self.video.protagonista + ': ' + self.video.descripcion,
            'value': self.$refs.url.value
          })
        }
        navigator.clipboard.writeText(this.$refs.url.value)
        .then(() => { this.$noty.success("Copiado!") })
        .catch((error) => { this.$noty.error(`Copy failed! ${error}`) })
      }
    },
    mounted: function () {
      $(this.$refs.vueModalVerVideo).on("hidden.bs.modal", this.closeModalVerVideo)
      $(this.$refs.vueModalVerVideo).on("show.bs.modal", this.showModalVerVideo)
    },
    destroyed() {
      this.removePlayer();
    },

    watch: {
      'video.id_tweet': {
        handler: function(val, oldVal) {
          // var self = this
          // this.show_video_publicidad = false
          // if(this.$store.state.session.videos_publicidad % 5 == 0){
          //   this.show_video_publicidad = true
          // }
          //
          // this.$store.dispatch("setVideos_publicidad", (parseInt(this.$store.state.session.videos_publicidad) + 1))
          //
   //        if(this.$route.name == "PageVideo") {
   //          this.$nextTick(() => {
   // // The whole view is rendered, so I can safely access or query
   // // the DOM. ¯\_(ツ)_/¯
   // console.log(this.video)
   //           this.$refs.plyr.player.source = { type: 'video', title: this.video.descripcion, sources: [{ src: this.getFile(), type: 'video/mp4' }] };
   //           alert("me cago en todo")
   //         })
   //        }
        }
      },
      deep: true
    }
  }
</script>

<style scoped>
  #div_loader {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    background-color: #000000;
  }

  .logo_container {
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
